<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 300px;">
          <label>日期</label>
          <range-date @change="handleDateChange" ref="createTime"></range-date>
        </div>
        <div class="one-item" style="padding-left: 10px">
          <a-checkbox
              class="input"
              v-model="queryParams.statisticMode"
              placeholder="显示操作类型"
              @change="search(1)"
          >统计次数模式
          </a-checkbox>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
        <a-button style="margin-left: 8px" @click="reset">重置</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
    </a-table>
  </a-card>
</template>

<script>
import {ListMixin} from "@/mixin/list-mixin";
import RangeDate from '@/components/datetime/RangeDate'

export default {
  name: "CheaperSearchList",
  mixins: [ListMixin],
  components: {RangeDate},
  data() {
    return {
      listGetUrl: "cheaper/searchList",
      showLoading: false,
      pagination: {
        defaultPageSize: 20
      }
    }
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let list = [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
          statisticMode: 2,
        },
        {
          title: "搜索词语",
          align: "center",
          width: 200,
          dataIndex: "word",
        },
        {
          title: "用户名称",
          align: "center",
          width: 200,
          dataIndex: "userInfo.username",
        },
        {
          title: "搜索时间",
          width: 180,
          align: "center",
          dataIndex: "createTime",
        },
        {}
      ];
      let listStatictic = [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "搜索词语",
          align: "center",
          width: 200,
          dataIndex: "word",
        },
        {
          title: "次数",
          width: 250,
          align: "center",
          dataIndex: "total",
        },
        {statisticMode: 2}
      ];
      return this.queryParams.statisticMode ? listStatictic : list;
    },
  },
  mounted() {
    this.search(1);
  },
  methods: {
    afterFetch(data) {
      if (this.queryParams.statisticMode) {
        data.records.forEach((v, index) => {
          v.id = (data.current - 1) * data.size + index + 1
        })
      }
    },
    handleDateChange(value) {
      if (value) {
        this.queryParams.createTimeFrom = value[0]
        this.queryParams.createTimeTo = value[1]
      }
    }
  }
}
;
</script>

<style lang="less" scoped>
.textClick {
  color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
